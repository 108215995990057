import { atom } from 'recoil';
import type { VoiceTag } from '@modules/types/voice';

export const IsMobileSize = atom<boolean>({
    key: 'isMobileSize',
    default: false,
});

export const errorState = atom<boolean>({
    key: 'errorState',
    default: false,
});

export const IsMenubarOpen = atom<boolean>({
    key: 'isMenubarOpen',
    default: false,
});

export const RTimeoutError = atom<
    '' | 'upload' | 'recordSound' | 'noTime' | 'timeout' | 'noCharacter' | 'exceededCharacters'
>({
    key: 'timeoutError',
    default: '',
});

export const RIsPreparationModalOpen = atom<boolean>({
    key: 'isPreparationModalOpen',
    default: false,
});

// export const IsReceiveUpdatesModalOpen = atom<boolean>({
//     key: 'isReceiveUpdatesModalOpen',
//     default: false,
// });

export const RIsPermissionError = atom<boolean>({
    key: 'isPermissionError',
    default: false,
});

export const RVoiceTagList = atom<VoiceTag | null>({
    key: 'voiceTagList',
    default: null,
});

export const RShortsSelectedVoice = atom<{
    name: string;
    script: string;
    profileImageURL: string;
    sampleSoundURL: string;
    voiceId: string;
} | null>({
    key: 'shortsSelectedVoice',
    default: null,
});

export const RShortsOriginalVideo = atom<{ file: File; duration: number; URL: string } | null>({
    key: 'shortsOriginalVideo',
    default: null,
});
export const RShortsConvertedVideo = atom<{ duration: number; URL: string } | null>({
    key: 'shortsConvertedVideo',
    default: null,
});
