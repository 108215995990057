import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import { RCharacterCount } from '@modules/state/studio';
import { RAvailableCharacters } from '@modules/state/user';
import styles from './index.module.scss';

interface Props {
    type: 'upload' | 'recordSound' | 'noTime' | 'timeout' | 'noCharacter' | 'exceededCharacters';
    onClose?: () => void;
    onStopClick?: () => void;
    onReRecordClick?: () => void;
}

const TimeoutErrorModal = ({ type, onClose, onStopClick, onReRecordClick }: Props) => {
    const { t } = useTranslation('studio');
    const text = t('timeoutErrorModal');

    const router = useRouter();

    const handleNoTime = () => {
        router.push('/dashboard/plan');
        if (onClose) onClose();
    };

    const characterCount = useRecoilValue(RCharacterCount);
    const availableCharacterCount = useRecoilValue(RAvailableCharacters);

    return (
        <div className={styles.modal_background}>
            <div className={styles.error_modal}>
                <div className={styles.text_area}>
                    <span className={styles.title}>{text[type]}</span>
                    {type === 'recordSound' && <span className={styles.guide}>{text.guide}</span>}
                    {type === 'noCharacter' && <span className={styles.guide}>{text.ttsBetaGuide}</span>}
                    {type === 'exceededCharacters' && (
                        <span className={styles.guide}>
                            {text.createdCharacters}:{' '}
                            <b>
                                {characterCount}
                                {text.unit}
                            </b>{' '}
                            | {text.remainingCharacters}:{' '}
                            <b>
                                {availableCharacterCount}
                                {text.unit}
                            </b>
                        </span>
                    )}
                </div>
                <div className={styles.btn_area}>
                    {['upload', 'timeout', 'exceededCharacters'].includes(type) ? (
                        <button type="button" onClick={onClose} className={styles.confirm_btn}>
                            {text.confirm}
                        </button>
                    ) : (
                        <>
                            <button
                                type="button"
                                onClick={type !== 'recordSound' ? onClose : onStopClick}
                                className={styles.stop_btn}
                            >
                                {type === 'recordSound' ? text.yes : text.confirm}
                            </button>
                            <button
                                type="button"
                                onClick={type !== 'recordSound' ? handleNoTime : onReRecordClick}
                                className={styles.re_record_btn}
                            >
                                {type === 'recordSound' && text.reRecordSound}
                                {(type === 'noTime' || type === 'noCharacter') && text.plan}
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default TimeoutErrorModal;
