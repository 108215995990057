import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';

import useTranslation from '@modules/hooks/useTranslation';
import useOverlay from '@modules/hooks/useOverlay';

import { IsMobileSize } from '@modules/state/common';
import { User } from '@modules/state/user';

import Nav from '@components/Header/Nav';
import Logo from '@components/Header/Logo';

import styles from './index.module.scss';

const Header = () => {
    const { t, locale } = useTranslation('common');
    const text = t('header');

    const router = useRouter();
    const { pathname } = router;

    const isMobile = useRecoilValue(IsMobileSize);

    const { isOpen: isMobileMenuOpen, handleOpen: handleMobileMenu, close: closeMobileMenu } = useOverlay();

    const user = useRecoilValue(User);
    const handleStartClick = async () => {
        if (user.accessToken) {
            await router.push('/studio/text-to-speech');
            return;
        }
        await router.push('/login');
    };

    const handleLanguage = () => {
        router.push(
            {
                pathname: router.pathname,
                query: router.query,
            },
            {
                pathname: router.pathname,
                query: router.query,
            },
            { locale: locale === 'ko' ? 'en' : 'ko' },
        );
    };

    useEffect(() => {
        closeMobileMenu();
    }, [pathname]);

    if (isMobile) {
        return (
            <header className={`${styles.header} ${styles.mobile}`}>
                <div className={styles.inner}>
                    <div className={styles.logo_area}>
                        <Logo />
                    </div>

                    <button
                        type="button"
                        onClick={handleMobileMenu}
                        className={`${styles.menu_btn} ${isMobileMenuOpen ? styles.open : ''}`}
                    />
                </div>

                {isMobileMenuOpen && <Nav />}
            </header>
        );
    }

    return (
        <header className={styles.header}>
            <div className={styles.inner}>
                <div className={styles.logo_area}>
                    <Logo />
                </div>

                <Nav />
                <button type="button" onClick={handleLanguage} className={styles.language}>
                    {locale === 'ko' ? '한국어' : 'English'}
                </button>

                <button
                    type="button"
                    onClick={handleStartClick}
                    className={`${styles.start} ${user.accessToken ? styles.studio : ''}`}
                >
                    {!user.accessToken ? text.join : text['내 스튜디오']}
                </button>
            </div>

            {isMobileMenuOpen && <Nav />}
        </header>
    );
};

export default Header;
