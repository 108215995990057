export const setModalClose = (key: string) => {
    let todayDate = new Date();
    todayDate = new Date(parseInt(String(todayDate.getTime() / 86400000), 10) * 86400000 + 54000000);
    let expire = 1;
    if (todayDate > new Date()) expire -= 1;
    todayDate.setDate(todayDate.getDate() + expire);
    document.cookie = `${key}=false;path=/;expires=${todayDate}`;
};
export const getModalClose = (key: string) => {
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let x = cookie[i].substring(0, cookie[i].indexOf('='));
        const y = cookie[i].substring(cookie[i].indexOf('=') + 1);
        x = x.replace(/^\s+|\s+$/g, '');
        if (x === key) return y;
    }
    return true;
};

export const setToken = (accessToken: string, refreshToken?: string) => {
    document.cookie = `aT=${accessToken};path=/;max-age=86400;`;
    if (refreshToken) document.cookie = `rT=${refreshToken};path=/;max-age=${86400 * 14};`;
};

export const getUserToken = () => {
    if (typeof window === 'undefined') {
        return null;
    }
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let x = cookie[i].substring(0, cookie[i].indexOf('='));
        const y = cookie[i].substring(cookie[i].indexOf('=') + 1);
        x = x.replace(/^\s+|\s+$/g, '');
        if (x === 'aT') return y;
    }
    return null;
};

export const deleteToken = () => {
    document.cookie = 'aT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.voli.ai;';
    document.cookie = 'rT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.voli.ai;';
    document.cookie = 'aT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
    document.cookie = 'rT=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
};

export const setEmail = (value: string) => {
    const date = new Date();
    date.setTime(date.getTime() + 180 * 1000);
    document.cookie = `certE=${value}/${date};path=/;secure;max-age=180`;
};

export const getCookie = (key: string) => {
    const cookie = document.cookie.split(';');
    for (let i = 0; i < cookie.length; i++) {
        let x = cookie[i].substring(0, cookie[i].indexOf('='));
        const y = cookie[i].substring(cookie[i].indexOf('=') + 1);
        x = x.replace(/^\s+|\s+$/g, '');
        if (x === key) return y;
    }
    return null;
};
