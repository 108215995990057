import { useEffect, useState } from 'react';

const useOverlay = () => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const handleOpen = () => setIsOpen((isOpen) => !isOpen);
    const close = () => setIsOpen(false);

    useEffect(() => {
        if (isOpen) {
            document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; overflow-y: scroll; width: 100%;`;
        } else {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
    }, [isOpen]);

    return { isOpen, handleOpen, close };
};

export default useOverlay;
