import React, { ReactNode, useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

export const Mobile = ({ children }: { children: ReactNode }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });

    const [isMobileWidth, setIsMobileWidth] = useState<boolean>(false);

    useEffect(() => {
        setIsMobileWidth(isMobile);
    }, [isMobile]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{isMobileWidth && children}</>;
};

export const PC = ({ children }: { children: ReactNode }) => {
    const isPc = useMediaQuery({ minWidth: 768 });

    const [isPcWidth, setIsPcWidth] = useState<boolean>(false);

    useEffect(() => {
        setIsPcWidth(isPc);
    }, [isPc]);

    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{isPcWidth && children}</>;
};
