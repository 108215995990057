import Link from 'next/link';
import useTranslation from '@modules/hooks/useTranslation';
import styles from '@styles/common2.module.scss';
import { useRouter } from 'next/router';

const Footer = () => {
    const { t, locale } = useTranslation('common');
    const text = t('footer');

    const router = useRouter();

    const handleLanguage = () => {
        router.push(
            {
                pathname: router.pathname,
                query: router.query,
            },
            {
                pathname: router.pathname,
                query: router.query,
            },
            { locale: locale === 'ko' ? 'en' : 'ko' },
        );
    };

    return (
        <footer className={styles.footer}>
            <div className={styles.inner}>
                <div className={styles.top}>
                    <div className={styles.logo}>
                        <img src="/logo.svg" alt="LOGO" width={48} height={24} />
                        <img src="/VOLI-white.svg" alt="VOLI" width={55} height={18} />
                    </div>
                    <div className={styles.sns_btn_area}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                        <a
                            href="https://www.youtube.com/@VOLI_AI"
                            target="_blank"
                            className={styles.youtube}
                            rel="noreferrer"
                        />
                        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
                        <a
                            href="https://www.instagram.com/voli.ai/"
                            target="_blank"
                            className={styles.instagram}
                            rel="noreferrer"
                        />
                    </div>
                </div>

                <div className={styles.bottom}>
                    <div className={styles.left_bottom}>
                        <span>
                            {text.waveDeckInc}
                            <span className={styles.pc}> | </span>
                            <br className={styles.mobile} />
                            {text.ceo} | {text.businessRegNum}: 256-88-02671
                            {locale === 'ko' && (
                                <span>
                                    <span className={styles.pc}> | </span>
                                    <br className={styles.mobile} />
                                    통신판매번호: 2023-서울마포-2992
                                </span>
                            )}
                            <br />
                            {text.address}
                            <span className={styles.pc}> | </span>
                            <br className={styles.mobile} />
                            {text.email}: contact@wavedeck.ai
                            {locale === 'ko' && (
                                <>
                                    <br />
                                    Copyright © 2024 주식회사 웨이브덱 All Rights Reserved
                                </>
                            )}
                        </span>
                        <div className={styles.btn_area}>
                            <Link href="/terms/service">
                                <a target="_blank">{text.userGuide}</a>
                            </Link>
                            <Link href="/terms/privacy">
                                <a target="_blank">{text.privacyPolicy}</a>
                            </Link>
                        </div>
                    </div>
                    <button type="button" name="language" onClick={handleLanguage}>
                        {text.language}
                    </button>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
