import common from './common.json';
import join from './join.json';
import login from './login.json';
import user from './user.json';
import home from './home.json';
import script from './script.json';
import actors from './actors.json';
import studio from './studio.json';
import studio2 from './studio2.json';
import guide from './guide.json';
import plan from './plan.json';
import lab from './lab.json';
import contents from './contents.json';
import marketing from './terms/marketing.json';
import privacy from './terms/privacy.json';
import service from './terms/service.json';
import service240502 from './terms/service-240502.json';
import userPlan from './dashboardPlan.json';
import dashboard from './dashboard.json';
import notice from './notice.json';
import faq from './faq.json';

interface Locales {
    [key: string]: {
        [key: string]: string | any;
    };
}

const ko: Locales = {
    common: { ...common },
    join: { ...join },
    login: { ...login },
    user: { ...user },
    home: { ...home },
    script: { ...script },
    actors: { ...actors },
    studio: { ...studio },
    studio2: { ...studio2 },
    guide: { ...guide },
    plan: { ...plan },
    lab: { ...lab },
    contents: { ...contents },
    marketing: { ...marketing },
    privacy: { ...privacy },
    service: { ...service },
    service240502: { ...service240502 },
    userPlan: { ...userPlan },
    dashboard: { ...dashboard },
    notice: { ...notice },
    faq: { ...faq },
};

export default ko;
