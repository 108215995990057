import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useMediaQuery } from 'react-responsive';
import { getUserToken } from '@modules/utils/cookie';
import { getUser } from '@modules/utils/user';
import { planFormatting } from '@modules/utils/formatter';
import { getPlanListAPI } from '@modules/api/plan';
import { errorState, IsMobileSize, RTimeoutError, RIsPermissionError } from '@modules/state/common';
import { RPlanList } from '@modules/state/plan';
import { User, UserPlan } from '@modules/state/user';
import { Header, Footer, MenuBar } from '@components/index';
import ErrorModal from '@components/modal/Error';
import PermissionError from '@components/modal/PermissionError';
import TimeoutErrorModal from '@components/studio/TimeoutErrorModal';
import VoiceSlotAlertModal from '@components/modal/VoiceSlotAlert';

const RootLayout = ({ children }: { children: React.ReactNode }) => {
    const router = useRouter();
    const { locale, pathname } = router;

    const isMobile = useMediaQuery({ maxWidth: 767 });

    const setIsMobileSize = useSetRecoilState(IsMobileSize);

    const [isUserLoading, setIsUserLoading] = useState(true);
    const [user, setUser] = useRecoilState(User);
    const [userPlan, setUserPlan] = useRecoilState(UserPlan);
    // const setReceivesUpdates = useSetRecoilState(RReceivesUpdates);

    const isError = useRecoilValue(errorState);

    const setPlanList = useSetRecoilState(RPlanList);
    const { data: planListResponse } = getPlanListAPI(locale as string);

    const reLogin = async () => {
        const data = await getUser();

        if (!data) {
            router.reload();
            return;
        }

        const { userInfo: info, userPlanInfo: planInfo } = data;

        setUser({ ...user, ...info });
        setUserPlan({ ...userPlan, ...planInfo });
        // setReceivesUpdates(receivesUpdates);

        setIsUserLoading(false);
    };

    useLayoutEffect(() => {
        if (getUserToken() === null) {
            setIsUserLoading(false);

            if (pathname.includes('/dashboard') || pathname.includes('/studio')) {
                router.push('/');
            }
        } else {
            reLogin();
        }
    }, []);

    useEffect(() => {
        setIsMobileSize(isMobile);
    }, [isMobile]);

    useEffect(() => {
        if (planListResponse === undefined) return;

        const { success, data } = planListResponse;
        if (!success) return;

        setPlanList(planFormatting(data));
    }, [planListResponse]);

    const [isPermissionError, setIsPermissionError] = useRecoilState(RIsPermissionError);
    const [timeoutError, setTimeoutError] = useRecoilState(RTimeoutError);

    return (
        <>
            {!pathname.includes('/dashboard') && !pathname.includes('/studio') && (
                <>
                    {!(
                        pathname.includes('/[social]') ||
                        pathname.includes('/join') ||
                        pathname.includes('/login') ||
                        pathname.includes('/user') ||
                        pathname.includes('/contest') ||
                        pathname.includes('/labs/shorts') ||
                        pathname.includes('/labs/shorts/start')
                    ) && <Header />}

                    {!pathname.includes('/contest') ? <main>{children}</main> : children}
                </>
            )}

            {!isUserLoading && (pathname.includes('dashboard') || pathname.includes('/studio')) && (
                <>
                    {!pathname.includes('/dashboard/studio') ? (
                        <>
                            <main className="divided-page">
                                <MenuBar />
                                <>{children}</>
                            </main>
                            {/* <Mobile> */}
                            {/*    <div className={styles.create_btn_area}> */}
                            {/*        {showsTypeList && ( */}
                            {/*            <CreateType version="mobile" onClose={() => setShowsTypeList(false)} /> */}
                            {/*        )} */}

                            {/*        <button */}
                            {/*            type="button" */}
                            {/*            onClick={() => setShowsTypeList(!showsTypeList)} */}
                            {/*            className={styles.create_btn} */}
                            {/*        /> */}
                            {/*    </div> */}
                            {/* </Mobile> */}
                        </>
                    ) : (
                        children
                    )}

                    {userPlan.mustChooseCustomVoice && pathname !== '/dashboard/library/slots' && (
                        <VoiceSlotAlertModal />
                    )}
                </>
            )}

            {['/', '/plan', '/actors', '/notice'].includes(pathname) && <Footer />}

            {isError && <ErrorModal />}
            {isPermissionError && <PermissionError onClose={() => setIsPermissionError(false)} />}
            {timeoutError !== '' && <TimeoutErrorModal type={timeoutError} onClose={() => setTimeoutError('')} />}
        </>
    );
};

export default RootLayout;
