import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useRouter } from 'next/router';
import Link from 'next/link';
import useTranslation from '@modules/hooks/useTranslation';
import useOverlay from '@modules/hooks/useOverlay';
import useOutsideClick from '@modules/hooks/useOutsideClick';
import useHeightObserver from '@modules/hooks/useHeightObserver';
import { deleteToken } from '@modules/utils/cookie';
import { timeFormatting } from '@modules/utils/time';
import { IsMenubarOpen, IsMobileSize } from '@modules/state/common';
import { RPlanList } from '@modules/state/plan';
import { AvailableTime, RAvailableCharacters, RemainingCustomCount, User, UserPlan } from '@modules/state/user';
import Toast from '@components/Toast';
import styles from '@styles/dashboard/component.module.scss';
import Logo from 'public/logo-voli.svg';
import {
    Keyboard,
    KeyboardVoice,
    LightbulbOutlined,
    CreditCard,
    HelpOutline,
    Smartphone,
    Help,
    Settings,
    OpenInNew,
} from '@mui/icons-material';

const Menubar = () => {
    const router = useRouter();
    const { pathname } = router;

    const { t, locale } = useTranslation('common');
    const text = t('menuBar');

    const { elementRef, height, scrollPosition, isScrolledToBottom } = useHeightObserver<HTMLUListElement>();

    const isMobileSize = useRecoilValue(IsMobileSize);

    const [isOpen, setIsOpen] = useRecoilState(IsMenubarOpen);

    const settingsRef = useRef<HTMLUListElement>(null);
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    useOutsideClick(settingsRef, () => {
        setIsSettingsOpen(false);
    });

    const { isOpen: isLogoutToastOpen, handleOpen: handleLogoutToast, close: closeLogoutToast } = useOverlay();

    const handleLogout = () => {
        deleteToken();
        handleLogoutToast();
    };
    const handleByeModalClose = async () => {
        closeLogoutToast();
        await router.push('/login');
        await router.reload();
    };

    const planList = useRecoilValue(RPlanList);

    const { nickname } = useRecoilValue(User);
    const { planName, usedCustomVoice } = useRecoilValue(UserPlan);
    const availableTime = useRecoilValue(AvailableTime);
    const availableCharacters = useRecoilValue(RAvailableCharacters);
    const availableCustomVoice = useRecoilValue(RemainingCustomCount);

    const totalAvailableTime = planList.filter((item) => item.name === planName)[0]?.availableTime;
    const totalCharacters = planList.filter((item) => item.name === planName)[0]?.availableCharacters;
    const totalCustomVoices = planList.filter((item) => item.name === planName)[0]?.customVoice;

    useEffect(() => {
        if (isOpen) {
            document.body.style.cssText = `position: fixed; top: -${window.scrollY}px; overflow-y: scroll; width: 100%;`;
        } else {
            const scrollY = document.body.style.top;
            document.body.style.cssText = '';
            window.scrollTo(0, parseInt(scrollY || '0', 10) * -1);
        }
    }, [isOpen]);

    if (planName === '') return <aside />;

    return (
        <>
            {isOpen && <div role="presentation" onClick={() => setIsOpen(false)} className={styles.background_menu} />}

            <aside className={`${styles['menu-bar']} ${isOpen ? styles.open : ''}`}>
                <div className={styles.logo_area}>
                    <Link href="/studio/text-to-speech">
                        <a aria-label="VOLI Logo" className={styles.logo}>
                            <Logo height={25} />
                        </a>
                    </Link>
                    <a href={process.env.NEXT_PUBLIC_URL} target="_blank" className={styles.main_link} rel="noreferrer">
                        <OpenInNew sx={{ fontSize: 18, color: '#BBBBBB' }} />
                        <span>{t('header.openVoli')}</span>
                    </a>
                </div>

                <ul
                    ref={elementRef}
                    aria-label="Navigation"
                    className={`${styles.navigation} ${height < 396 ? styles.scroll : ''} ${
                        scrollPosition === 0 ? styles.top : ''
                    } ${isScrolledToBottom ? styles.bottom : ''}`}
                >
                    <li aria-label="Studio">
                        <h2>Studio</h2>
                        <ul className={styles.menu_list}>
                            <li className={styles.menu_item}>
                                <Link href="/studio/text-to-speech">
                                    <a
                                        aria-label="Text to Speech"
                                        className={pathname.includes('/studio/text-to-speech') ? styles.active : ''}
                                    >
                                        <Keyboard sx={{ fontSize: 20, color: '#2F3237' }} />
                                        {t('menuBar.TTS')}
                                    </a>
                                </Link>
                            </li>
                            <li className={styles.menu_item}>
                                <Link href="/studio/speech-to-speech">
                                    <a
                                        aria-label="Speech to Speech"
                                        className={pathname.includes('/studio/speech-to-speech') ? styles.active : ''}
                                    >
                                        <span
                                            className="material-symbols-outlined"
                                            style={{ fontSize: 20, color: '#2F3237' }}
                                        >
                                            voice_selection
                                        </span>
                                        {t('menuBar.STS')}
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li aria-label="Voice">
                        <h2>Voice</h2>
                        <ul className={styles.menu_list}>
                            <li className={styles.menu_item}>
                                <Link href="/dashboard/library">
                                    <a
                                        aria-label="Voice Library"
                                        className={pathname === '/dashboard/library' ? styles.active : ''}
                                    >
                                        <KeyboardVoice sx={{ fontSize: 20, color: '#2F3237' }} />
                                        {t('menuBar.voiceLibrary')}
                                    </a>
                                </Link>
                            </li>
                            <li className={styles.menu_item}>
                                <Link href="/dashboard/custom">
                                    <a
                                        aria-label="Custom Voice"
                                        className={pathname === '/dashboard/custom' ? styles.active : ''}
                                    >
                                        <LightbulbOutlined sx={{ fontSize: 20, color: '#2F3237' }} />
                                        {t('menuBar.customVoice')}
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </li>
                    <li aria-label="My page">
                        <h2>My page</h2>
                        <ul className={styles.menu_list}>
                            <li className={styles.menu_item}>
                                <Link href="/dashboard/plan">
                                    <a
                                        aria-label="My Plan"
                                        className={pathname.includes('/dashboard/plan') ? styles.active : ''}
                                    >
                                        <CreditCard sx={{ fontSize: 20, color: '#2F3237' }} />
                                        {t('menuBar.plan')}
                                    </a>
                                </Link>
                            </li>
                            <li className={styles.menu_item}>
                                <Link href="/dashboard/help">
                                    <a
                                        aria-label="Help"
                                        className={pathname === '/dashboard/help' ? styles.active : ''}
                                    >
                                        <HelpOutline sx={{ fontSize: 20, color: '#2F3237' }} />
                                        {t('menuBar.help')}
                                    </a>
                                </Link>
                            </li>
                        </ul>
                    </li>

                    <li aria-label="Labs">
                        <h2>
                            Labs
                            <button type="button" className={styles.question_btn}>
                                <Help sx={{ fontSize: 14, color: '#C0C1C3' }} />
                            </button>
                            <span className={styles.labs_guide}>{t('menuBar.labsGuide')}</span>
                        </h2>
                        <ul className={styles.menu_list}>
                            <li className={styles.menu_item}>
                                <a aria-label="Shorts" href="/labs/shorts" target="_blank">
                                    <Smartphone sx={{ fontSize: 20, color: '#2F3237' }} />
                                    {t('menuBar.shorts')}
                                    <span className={styles.beta}>Beta</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>

                <div className={styles.profile_area}>
                    <div className={styles.upgrade_area}>
                        <button
                            type="button"
                            onClick={() => router.push('/dashboard/plan/upgrade')}
                            className={styles.upgrade_btn}
                        >
                            {t('menuBar.upgrade')}
                        </button>
                    </div>

                    <div className={styles.user_info}>
                        <span className={styles.nickname}>{nickname}</span>
                        <div className={styles.plan}>{planName}</div>
                        <button
                            type="button"
                            onClick={() => {
                                if (!isMobileSize) {
                                    setIsSettingsOpen(!isSettingsOpen);
                                } else {
                                    router.push('/dashboard/settings');
                                }
                            }}
                            className={styles.settings}
                        >
                            <Settings sx={{ fontSize: 20, color: '#717171' }} />
                        </button>

                        {isSettingsOpen && (
                            <ul ref={settingsRef} className={styles.settings_list}>
                                <li className={styles.settings_item}>
                                    <button
                                        type="button"
                                        aria-label="Settings"
                                        onClick={() => {
                                            router.push('/dashboard/settings');
                                            setIsSettingsOpen(false);
                                        }}
                                    >
                                        {t('menuBar.settings')}
                                    </button>
                                </li>
                                <li className={styles.settings_item}>
                                    <button type="button" aria-label="Logout" onClick={handleLogout}>
                                        {t('menuBar.logout')}
                                    </button>
                                </li>
                            </ul>
                        )}
                    </div>

                    <div className={styles.usage_info}>
                        <div className={styles.title}>{t('menuBar.STS')}</div>
                        <div className={styles.usage}>
                            <span className={styles.text}>
                                <b>{timeFormatting(locale as 'ko' | 'en', availableTime)}</b>&nbsp;|&nbsp;
                                {timeFormatting(locale as 'ko' | 'en', totalAvailableTime).split(' ')[0]}
                            </span>
                            <div
                                className={styles.progress}
                                style={{
                                    background: `conic-gradient(#D9D9D9 0% ${
                                        100 - (availableTime / totalAvailableTime) * 100
                                    }%, #717171 ${100 - (availableTime / totalAvailableTime) * 100}% 100%)`,
                                }}
                            >
                                <span className="material-symbols-outlined" style={{ fontSize: 14, color: 'white' }}>
                                    voice_selection
                                </span>
                            </div>
                        </div>
                        <div className={styles.title}>{t('menuBar.TTS')}</div>
                        <div className={styles.usage}>
                            <span className={styles.text}>
                                <b>
                                    {availableCharacters.toLocaleString('ko-KR')}
                                    {text.count}
                                </b>
                                &nbsp;|&nbsp;
                                {totalCharacters?.toLocaleString('ko-KR')}
                                {locale === 'ko' && text.count}
                            </span>
                            <div
                                className={styles.progress}
                                style={{
                                    background: `conic-gradient(#D9D9D9 0% ${
                                        100 - (availableCharacters / totalCharacters) * 100
                                    }%, #717171 ${100 - (availableCharacters / totalCharacters) * 100}% 100%)`,
                                }}
                            >
                                <Keyboard sx={{ fontSize: 16, color: 'white' }} />
                            </div>
                        </div>
                        <div className={styles.title}>Custom</div>
                        <div className={styles.usage}>
                            <span className={styles.text}>
                                <b>
                                    {usedCustomVoice}
                                    {t('menuBar.slotCount')}&nbsp;
                                    {t('menuBar.slot')}
                                </b>
                                &nbsp;|&nbsp;
                                {totalCustomVoices !== Infinity ? totalCustomVoices : '∞'}
                                {t('menuBar.slotCount')}
                            </span>
                            <div
                                className={styles.progress}
                                style={{
                                    background:
                                        totalCustomVoices > 0
                                            ? `conic-gradient(#D9D9D9 0% ${
                                                  100 - (availableCustomVoice / totalCustomVoices) * 100
                                              }%, #717171 ${
                                                  100 - (availableCustomVoice / totalCustomVoices) * 100
                                              }% 100%)`
                                            : '#D9D9D9',
                                }}
                            >
                                <LightbulbOutlined sx={{ fontSize: 16, color: 'white' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </aside>

            {isLogoutToastOpen && (
                <Toast
                    isToast
                    onClose={handleByeModalClose}
                    iconSrc="/images/emoji/hand-with-fingers-splayed@3x.png"
                    text={`${nickname.length > 13 ? `${nickname.slice(0, 13)}...` : nickname} ${t('bye')}`}
                />
            )}
        </>
    );
};

export default Menubar;
