import { atom, selector } from 'recoil';
import type { UserInputType, UserPlanType, UserType } from '@modules/types/user';
import { RPlanList } from '@modules/state/plan';

export const PreviousPage = atom<string>({
    key: 'previousPage',
    default: '',
});

const initialState = {
    type: '',
    email: '',
    nickname: '',
    profileImageURL: '',
    firstName: '',
    lastName: '',
    gender: '',
    job: [],
    belong: '',
    purpose: [],
    isMarketing: null,
};

export const userInitialState = {
    ...initialState,
    accessToken: null,
};

export const Join = atom<UserInputType>({
    key: 'join',
    default: {
        ...initialState,
        type: '',
        password: '',
        locale: '',
        phoneNumber: '',
    },
});

export const User = atom<UserType>({
    key: 'user',
    default: userInitialState,
});

export const UserPlan = atom<UserPlanType>({
    key: 'userPlan',
    default: {
        planName: 'Free',
        usedTime: 0,
        usedCharacters: 0,
        usedCustomVoice: 0,
        planStartDate: '',
        planEndDate: '',
        planRenewalDate: '',
        mustChooseCustomVoice: false,
        usedCoupon: false,
        isUsedAIDuringPlan: false,
        hasPaymentRecord: false,
        hasPurchasedWithDiscount: false,
    },
});

export const AvailableTime = selector({
    key: 'availableTime',
    get: ({ get }) => {
        const { planName, usedTime } = get(UserPlan);
        const planList = get(RPlanList);

        if (planName === '') return 0;

        const planAvailableTime = planList.find((item) => item.name === planName)?.availableTime;
        if (planAvailableTime === undefined) return 0;

        return planAvailableTime - usedTime > 0 ? planAvailableTime - usedTime : 0;
    },
});

export const RAvailableCharacters = selector({
    key: 'availableCharacters',
    get: ({ get }) => {
        const { planName, usedCharacters } = get(UserPlan);
        const planList = get(RPlanList);

        if (planName === '') return 0;

        const planAvailableCharacters = planList.find((item) => item.name === planName)?.availableCharacters;
        if (planAvailableCharacters === undefined) return 0;

        return planAvailableCharacters - usedCharacters > 0 ? planAvailableCharacters - usedCharacters : 0;
    },
});

export const RemainingCustomCount = selector({
    key: 'remainingCustomCount',
    get: ({ get }) => {
        const { planName, usedCustomVoice } = get(UserPlan);
        const planList = get(RPlanList);

        if (planName === '') return 0;

        const availableCustomVoice = planList.filter((item) => item.name === planName)[0]?.customVoice;
        const remainingCustomVoice = availableCustomVoice - usedCustomVoice;
        return remainingCustomVoice > 0 ? remainingCustomVoice : 0;
    },
});

export const RReceivesUpdates = atom<boolean>({
    key: 'receivesUpdates',
    default: false,
});

export const RIsAgreePlanChangeModalOpen = atom<boolean>({
    key: 'isAgreePlanChangeModalOpen',
    default: false,
});
