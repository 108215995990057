import { useMutation, useQuery } from 'react-query';
import api from '@modules/api/index';
import type { ApiResponse } from '@modules/types/api';
import type { ReasonsListResponse, UserResponse, HistoryResponse } from '@modules/types/api/user';
import type { UserInputType, UserType } from '@modules/types/user';

export const emailCheck = async (email: string) => {
    const { data } = await api.post('/member/checkemail', { email });
    return data;
};

export const useEmailCheck = (email: string) => {
    return useMutation(() => emailCheck(email));
};

const sendVerificationMailForJoin = async (email: string) => {
    const { data } = await api.post('/member/certemail', { email });
    return data;
};

export const sendVerificationMailForJoinAPI = (email: string) => {
    return useMutation(() => sendVerificationMailForJoin(email));
};

const postCertCheck = async (email: string, code: string) => {
    const { data } = await api.post('/member/checkcert', { email, cert: code });
    return data;
};

export const useCertCheck = (email: string, code: string) => {
    return useMutation(() => postCertCheck(email, code));
};

export const getJobList = async () => {
    const { data } = await api.post('/member/worklist');
    return data;
};

export const getPurposeList = async () => {
    const { data } = await api.post('/member/perposelist');
    return data;
};

const postJoin = async (userInfo: UserInputType, purposeEtcValue: string): Promise<ApiResponse<UserResponse>> => {
    const tmpPurposeList = userInfo.purpose.filter((purposeItem) => purposeItem !== '기타');
    tmpPurposeList.push(purposeEtcValue);

    const formData = {
        email: userInfo.email,
        type: userInfo.type,
        nickname: userInfo.email.split('@')[0],
        password: userInfo.password,
        firstname: userInfo.firstName,
        profile_img: userInfo.profileImageURL,
        local: userInfo.locale,
        gender: userInfo.gender,
        phone_number: userInfo.phoneNumber,
        work: userInfo.job.join(','),
        belong: userInfo.belong,
        perpose: userInfo.purpose.includes('기타') ? tmpPurposeList.join(',') : userInfo.purpose.join(','),
        marketing: userInfo.isMarketing ? 'Y' : 'N',
    };

    const { data } = await api.post('/member/createmember', formData);
    return data;
};

export const useJoin = (userInfo: UserInputType, purposeEtcValue: string) => {
    return useMutation(() => postJoin(userInfo, purposeEtcValue));
};

const postLogin = async (type: string, email: string, password: string): Promise<ApiResponse<UserResponse>> => {
    const { data } = await api.post('/member/login', { type, email, password });
    return data;
};

export const useLogin = (type: string, email: string, password: string) => {
    return useMutation(() => postLogin(type, email, password));
};

export const socialLogin = async (code: string, type: string): Promise<ApiResponse<UserResponse>> => {
    const { data } = await api.post('/member/snslogin', {
        code,
        state: type === 'naver' ? 'STATE_STRING' : '',
        type,
    });
    return data;
};

const checkPassword = async (password: string) => {
    const { data } = await api.post('/member/check_pwd', { pwd: password });
    return data;
};

export const checkPasswordAPI = () => {
    return useMutation((password: string) => checkPassword(password));
};

export const getUserInfo = async (): Promise<ApiResponse<UserResponse>> => {
    const { data } = await api.post('/member/getmember');
    return data;
};

const getDetailUser = async () => {
    const { data } = await api.post('/member/getmemberdetail');
    return data;
};

export const getDetailUserAPI = (enabled: boolean) => {
    const { data, error } = useQuery('getDetailUser', () => getDetailUser(), {
        retry: false,
        enabled,
    });

    return { success: data, fail: error };
};

const sendVerificationMailForFindPassword = async (email: string) => {
    const { data } = await api.post('/member/certemailpwd', { email });
    return data;
};

export const sendVerificationMailForFindPasswordAPI = (email: string) => {
    return useMutation(() => sendVerificationMailForFindPassword(email));
};

const updatePassword = async (email: string, password: string) => {
    const { data } = await api.post('/member/updatepwd', { email, password });
    return data;
};

export const updatePasswordAPI = (email: string, password: string) => {
    return useMutation(() => updatePassword(email, password));
};

const updateUser = async (userInfo: UserType) => {
    const formData = {
        email: userInfo.email,
        nickname: userInfo.nickname,
        profile_img: userInfo.profileImageURL,
        firstname: userInfo.firstName,
        secondname: userInfo.lastName,
        gender: userInfo.gender,
        work: userInfo.job.join(','),
        belong: userInfo.belong,
        marketing: userInfo.isMarketing === null ? '' : userInfo.isMarketing ? 'Y' : 'N',
    };

    const { data } = await api.post('/member/updatemember', formData);
    return data;
};

export const useUpdateUSer = (userInfo: UserType) => {
    return useMutation(() => updateUser(userInfo));
};

export const getReasonList = async (): Promise<ApiResponse<ReasonsListResponse>> => {
    // 탈퇴 사유 및 플랜 해지 사유
    const { data } = await api.post('/member/reasonlist');
    return data;
};

export const getReasonListAPI = () => {
    return useQuery('reasonList', () => getReasonList());
};

const withdraw = async (reason: string[], opinion: string) => {
    const { data } = await api.post('/member/signout', {
        reason_studio: reason.join(','),
        reason_community: '',
        opinion,
    });
    return data;
};

export const useWithdraw = (reason: string[], opinion: string) => {
    return useMutation(() => withdraw(reason, opinion));
};

const getStudioHistory = async (type: 'ALL' | 'STS' | 'TTS', page: number): Promise<ApiResponse<HistoryResponse>> => {
    const { data } = await api.get(`/studio/history/${type}?page=${page}&limit=10`);
    return data;
};

export const getStudioHistoryAPI = (type: 'ALL' | 'STS' | 'TTS', page: number | null, enabled: boolean) => {
    return useQuery(['historyList', type, page], () => getStudioHistory(type, page!), {
        retry: false,
        enabled: enabled && page !== null,
    });
};

const deleteHistoryItem = async (id: number) => {
    const { data } = await api.post('/studio/delHistory', { inference_id: id });
    return data;
};

export const deleteHistoryItemAPI = () => {
    return useMutation((id: number) => deleteHistoryItem(id));
};

const receiveUpdates = async (type: string): Promise<ApiResponse<{}>> => {
    const { data } = await api.post('/utils/marketting', { type });
    return data;
};

export const receiveUpdatesAPI = (type: string) => {
    return useMutation(() => receiveUpdates(type));
};

const unsubscribe = async (email: string): Promise<ApiResponse<{}>> => {
    const { data } = await api.post('/member/marketingYN', { email });
    return data;
};

export const unsubscribeAPI = () => {
    return useMutation((email: string) => unsubscribe(email));
};

const agreePlanChange = async () => {
    const { data } = await api.post('/utils/plan_agree', { agree_yn: 'Y' });
    return data;
};

export const agreePlanChangeAPI = () => {
    return useMutation(() => agreePlanChange());
};
