import useTranslation from '@modules/hooks/useTranslation';
import styles from '@components/modal/index.module.scss';

interface Props {
    onClose: () => void;
}

const PermissionError = ({ onClose }: Props) => {
    const { t } = useTranslation('common');

    return (
        <div className={styles.background}>
            <div className={styles.permission_error}>
                <p className={styles.title}>{t('permissionError')}</p>
                <div className={styles.btn_area}>
                    <button type="button" onClick={onClose} className={styles.confirm_btn}>
                        {t('ok')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PermissionError;
