import { useState, useEffect, useRef } from 'react';

function useHeightObserver<T extends HTMLElement>() {
    const [height, setHeight] = useState(0);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [isScrolledToBottom, setIsScrolledToBottom] = useState(false); // 스크롤 끝 여부 상태 추가
    const elementRef = useRef<T>(null);

    // 스크롤 이벤트 핸들러
    const handleScroll = () => {
        if (elementRef.current) {
            const { scrollTop } = elementRef.current; // 현재 스크롤 위치
            const { scrollHeight } = elementRef.current; // 전체 콘텐츠 높이
            const { clientHeight } = elementRef.current; // 보이는 영역 높이

            setScrollPosition(scrollTop);

            // 스크롤이 끝까지 내렸는지 확인
            if (Math.abs(scrollTop + clientHeight - scrollHeight) <= 1) {
                setIsScrolledToBottom(true);
            } else {
                setIsScrolledToBottom(false);
            }
        }
    };

    useEffect(() => {
        const element = elementRef.current;

        if (!element) {
            return; // element가 null이면 아무 작업도 하지 않음
        }

        // 높이 감지
        const observer = new ResizeObserver((entries) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const entry of entries) {
                const { height } = entry.contentRect;
                setHeight(height);
            }
        });

        // 해당 요소의 스크롤 이벤트 감지
        element.addEventListener('scroll', handleScroll);

        observer.observe(element);

        // 클린업 함수
        // eslint-disable-next-line consistent-return
        return () => {
            element.removeEventListener('scroll', handleScroll);
            observer.unobserve(element);
            observer.disconnect();
        };
    }, []);

    return { elementRef, height, scrollPosition, isScrolledToBottom };
}

export default useHeightObserver;
