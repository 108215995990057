import { atom, selector } from 'recoil';
import type { ConvertedFile, OriginalFile, STSSoundSettings, TTSSoundSettings } from '@modules/types/studio';
import type { VoiceModel } from '@modules/types/voice';

export const RType = atom<null | 'file' | 'recording' | 'typing'>({
    key: 'type',
    default: null,
});

export const RSelectedVoice = atom<VoiceModel | null>({
    key: 'selectedVoice',
    default: null,
});

export const ROriginalFile = atom<OriginalFile | null>({
    key: 'originalFile',
    default: null,
});

export const RScript = atom<string>({
    key: 'script',
    default: '',
});

export const RCharacterCount = selector({
    key: 'characterCount',
    get: ({ get }) => {
        const script = get(RScript);

        return script.length;
    },
});

export const RConvertedFile = atom<ConvertedFile | null>({
    key: 'convertedFile',
    default: null,
});

export const RSoundSettings = atom<STSSoundSettings | TTSSoundSettings>({
    key: 'soundSettings',
    default: {
        type: 'STS',
        pitch: 0,
        tempo: 100,
        volume: 1,
        pitchSettingMode: 'auto',
    },
});
