import { useSetRecoilState } from 'recoil';
import useTranslation from '@modules/hooks/useTranslation';
import { errorState } from '@modules/state/common';
import styles from '@styles/common.module.scss';

const ErrorModal = () => {
    const { t } = useTranslation('common');
    const text = t('errorModal');

    const setIsError = useSetRecoilState(errorState);

    return (
        <div role="presentation" onClick={() => setIsError(false)} className={styles.background_modal}>
            <div role="presentation" onClick={(e) => e.stopPropagation()} className={styles.error_modal}>
                <p className={styles.title}>
                    {text.title[0]}
                    <br className={styles.mobile} />
                    {text.title[1]}
                    <br />
                    {text.title[2]}
                    <br className={styles.mobile} />
                    {text.title[3]}
                </p>
                <div className={styles.sub_area}>
                    <span>
                        {text.text[0]}
                        <br className={styles.mobile} />
                        {text.text[1]}
                    </span>
                    <span>contact@wavedeck.ai</span>
                </div>
            </div>
        </div>
    );
};

export default ErrorModal;
