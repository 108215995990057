import { useEffect, useState } from 'react';
import { RecoilRoot } from 'recoil';
import { QueryClient, QueryClientProvider } from 'react-query';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { useRouter } from 'next/router';
import useTranslation from '@modules/hooks/useTranslation';
import { RootLayout } from '@components/index';
import * as gtag from '@modules/lib/gtag';
import '@styles/globals.css';

declare global {
    interface Window {
        Tally: any;
    }
}

const App = ({ Component, pageProps }: AppProps) => {
    const { t } = useTranslation('common');

    const [queryClient] = useState(
        () => new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } }),
    );

    const router = useRouter();
    const { pathname, locale } = router;

    useEffect(() => {
        if (process.env.NEXT_PUBLIC_NODE_ENV === 'development') return;

        const handleRouteChange = (url: URL) => {
            gtag.pageview(url);
        };

        router.events.on('routeChangeComplete', handleRouteChange);
        router.events.on('hashChangeComplete', handleRouteChange);
        // eslint-disable-next-line consistent-return
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
            router.events.off('hashChangeComplete', handleRouteChange);
        };
    }, [router.events]);

    return (
        <QueryClientProvider client={queryClient}>
            <RecoilRoot>
                {process.env.NEXT_PUBLIC_NODE_ENV !== 'development' && (
                    <>
                        <Script
                            strategy="afterInteractive"
                            src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
                        />
                        <Script
                            id="gtag-init"
                            strategy="afterInteractive"
                            dangerouslySetInnerHTML={{
                                __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
                            }}
                        />
                    </>
                )}

                <Head>
                    <meta content="width=device-width, initial-scale=1, user-scalable=no" name="viewport" />
                    {process.env.NEXT_PUBLIC_NODE_ENV === 'development' && (
                        <meta name="robots" content="noindex, nofollow" />
                    )}

                    <title>{t('title')}</title>
                    <meta
                        property="og:title"
                        content={
                            pathname.includes('/labs/shorts') ? 'AI 성대모사 쇼츠 제작 - VOLI x Shorts' : t('title')
                        }
                    />

                    <meta
                        name="keywords"
                        content="볼리, VOLI, AI 목소리, 무료 AI 목소리, 음성변환, 목소리 변환, 음성자산화, 음성거래, 목소리 거래, 목소리 플랫폼, 보이스 플랫폼, 보이스 마켓, 보이스 AI, 음성 AI, 보이스 어플리케이션, 보이스 앱, Voice Conversion, STS, TTS, Speech To Speech, Text To Speech, Voice Market, Voice Platform, Voice Marketplace, Voice AI, Voice Application, Voice App"
                    />
                    <meta name="description" content={t('description')} />
                    <meta name="theme-color" content="white" />
                    <meta name="mobile-web-app-capable" content="yes" />
                    <meta
                        property="og:url"
                        content={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }${pathname}`}
                    />
                    <meta property="og:type" content="website" />

                    <meta property="og:site_name" content="볼리 (VOLI)" />
                    <meta
                        property="og:description"
                        content={pathname.includes('/labs/shorts') ? '3초 완성 성대모사 쇼츠' : t('shortDescription')}
                    />
                    <meta
                        property="og:image"
                        content={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }/images/og_image.png`}
                    />
                    <meta name="twitter:card" content="summary" />
                    <meta name="twitter:site" content="@VOLI" />
                    <meta
                        name="twitter:url"
                        content={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }${pathname}`}
                    />
                    <meta name="twitter:title" content="볼리 (VOLI)" />
                    <meta name="twitter:description" content={t('shortDescription')} />
                    <meta
                        name="twitter:image"
                        content={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }/images/og_image.png`}
                    />

                    <link
                        rel="icon"
                        href={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }/images/Favicon.ico`}
                        type="image/x-icon"
                    />
                    <link
                        rel="shortcut icon"
                        href={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }/images/Favicon.ico`}
                        type="image/x-icon"
                    />
                    <link
                        rel="stylesheet"
                        as="style"
                        crossOrigin=""
                        href="https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable-dynamic-subset.min.css"
                    />
                    <link
                        rel="apple-touch-icon"
                        href={`${
                            process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                                ? process.env.NEXT_PUBLIC_URL_DEV
                                : process.env.NEXT_PUBLIC_URL
                        }/images/Favicon.png`}
                    />
                    <link href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined" rel="stylesheet" />
                    <script async src="https://tally.so/widgets/embed.js" />
                </Head>

                <RootLayout>
                    <Component {...pageProps} />
                </RootLayout>
            </RecoilRoot>
        </QueryClientProvider>
    );
};

export default App;
