import { useRecoilValue } from 'recoil';
import Link from 'next/link';
import useTranslation from '@modules/hooks/useTranslation';
import { PlanValue } from '@modules/state/plan';
import styles from '@components/modal/index.module.scss';

const VoiceSlotAlertModal = () => {
    const { t } = useTranslation('dashboard');
    const text = t('voiceSlotAlertModal');

    const customVoice = useRecoilValue(PlanValue({ key: 'customVoice' }));

    return (
        <div className={styles.background}>
            <div className={styles.voice_slot_alert}>
                <img src="/images/emoji/red-exclamation-mark.png" alt="" width={48} height={57} />
                <span className={styles.title}>{text.title}</span>
                <span className={styles.text}>
                    {text.text[0]} {customVoice}
                    {text.text[1]}
                </span>

                <Link href="/dashboard/library/slots">
                    <a className={styles.link_btn}>{text.goToLibrary}</a>
                </Link>
            </div>
        </div>
    );
};

export default VoiceSlotAlertModal;
