import { useMutation, useQuery } from 'react-query';
import api from '@modules/api/index';
import type { CardInput } from '@modules/types/plan';
import type { ApiResponse } from '@modules/types/api';
import type { PaymentDetailsResponse, PaymentHistoryResponse, PlanResponse } from '@modules/types/api/plan';
import { CardResponse } from '@modules/types/api/user';

const getPlanList = async (): Promise<ApiResponse<PlanResponse[]>> => {
    const { data } = await api.post('/utils/planlist');
    return data;
};

export const getPlanListAPI = (locale: string) => {
    return useQuery(['plan', locale], () => getPlanList(), { retry: false });
};

const getCardList = async (): Promise<ApiResponse<CardResponse[]>> => {
    const { data } = await api.post('/payment/cardList');
    return data;
};

export const getCardListAPI = (enabled: boolean) => {
    return useQuery('cardList', () => getCardList(), {
        retry: false,
        enabled,
    });
};

const deleteCardAPI = async (cardNumber: string) => {
    const { data } = await api.post('/payment/unRegistCard', { card_no: cardNumber });
    return data;
};

const registerCard = async (
    formData: CardInput,
): Promise<
    ApiResponse<{
        code: number;
        message: string | null;
        response: {
            card_code: string;
            card_name: string;
            card_number: string;
            card_type: number;
        };
    }>
> => {
    const cardNumberList = formData.cardNumber.split(' - ');
    const expirationDateList = formData.expirationDate.split(' / ');

    const { data } = await api.post('/payment/registcard', {
        card_type: formData.type === 'corporate' ? '법인' : '개인',
        card_number1: cardNumberList[0],
        card_number2: cardNumberList[1],
        card_number3: cardNumberList[2],
        card_number4: cardNumberList[3],
        expiry: `20${expirationDateList[1]}${expirationDateList[0]}`,
        birth: formData.birth,
        gender_no: formData.gender,
        pwd_2digit: formData.passwordFront2,
        main_card_yn: formData.isMainCard ? 'Y' : 'N',
    });

    if (data.success) {
        const { data: cardList, success } = await getCardList();
        if (success) {
            for (let i = 0; i < cardList.length; i++) {
                if (cardList[i].main_card_yn === 'N') {
                    // eslint-disable-next-line no-await-in-loop
                    await deleteCardAPI(cardList[i].card_no);
                }
            }
        }
    }

    return data;
};

export const registerCardAPI = (formData: CardInput) => {
    return useMutation(async () => registerCard(formData));
};

const purchase = async (plan: string, amount: number) => {
    const { data } = await api.post('/payment/payment', { plan, amount });
    return data;
};

export const purchaseAPI = (plan: string, amount: number) => {
    return useMutation(async () => purchase(plan, amount));
};

const getPaymentHistory = async (): Promise<ApiResponse<PaymentHistoryResponse[]>> => {
    const { data } = await api.post('/payment/list');
    return data;
};

export const getPaymentHistoryAPI = (enabled: boolean) => {
    return useQuery('paymentList', () => getPaymentHistory(), {
        retry: false,
        enabled,
    });
};

const getPaymentDetails = async (id: number): Promise<ApiResponse<PaymentDetailsResponse>> => {
    const { data } = await api.post('/payment/detail', { no: id });
    return data;
};

export const getPaymentDetailsAPI = (id: number) => {
    const { data, error } = useQuery(['paymentDetails', id], () => getPaymentDetails(id), {
        retry: false,
        enabled: !!id,
    });

    return { success: data, fail: error };
};

const upgradePlan = async (plan: string) => {
    const { data } = await api.post('/payment/upgradeplan', { toplan: plan });
    return data;
};
export const upgradePlanAPI = () => {
    return useMutation((plan: string) => upgradePlan(plan));
};

const downgradePlan = async (plan: string) => {
    const { data } = await api.post('/payment/downgradeplan', { toplan: plan });
    return data;
};
export const downgradePlanAPI = () => {
    return useMutation((plan: string) => downgradePlan(plan));
};

const terminatePlan = async (reasonList: string[], opinion: string) => {
    const { data } = await api.post('/payment/clearplan', { reason_clear: reasonList.join(','), option: opinion });
    return data;
};
export const terminatePlanAPI = () => {
    return useMutation(({ reasonList, opinion }: { reasonList: string[]; opinion: string }) =>
        terminatePlan(reasonList, opinion),
    );
};

export const getReceiptURL = async (uid: string) => {
    const { data } = await api.post('/payment/receipt', { imp_uid: uid });
    return data;
};

// coupon
const couponRegistration = async (coupon: string) => {
    const { data } = await api.post('/utils/accept_coupon', { num: coupon });

    return data;
};

export const couponRegistrationAPI = () => {
    return useMutation((coupon: string) => couponRegistration(coupon));
};

/// for paypal
const createPaypalOrder = async (plan: string) => {
    const { data } = await api.post('/payment/paypal_order', { plan });
    return data;
};

export const createPaypalOrderAPI = () => {
    return useMutation((plan: string) => createPaypalOrder(plan));
};

const requestPaypalApprove = async (plan: string, orderId: string) => {
    const { data } = await api.post('/payment/paypal_payment', {
        plan,
        orderID: orderId,
    });
    return data;
};
const savePaypalPayment = async (
    plan: string,
    orderId: string,
    payerId: string,
    merchantId: string,
    authorizationId: string,
) => {
    const { data } = await api.post('/payment/paypal_save', {
        plan_name: plan,
        orderID: orderId,
        payerID: payerId,
        merchant_uid: merchantId,
        authorization_id: authorizationId,
    });
    return data;
};
const upgradePaypalPayment = async (
    plan: string,
    orderId: string,
    payerId: string,
    merchantId: string,
    authorizationId: string,
) => {
    const { data } = await api.post('/payment/paypal_upgradeplan', {
        plan_name: plan,
        orderID: orderId,
        payerID: payerId,
        merchant_uid: merchantId,
        authorization_id: authorizationId,
    });
    return data;
};

const requestPaypalPayment = async (type: 'purchase' | 'upgrade', plan: string, orderId: string) => {
    const approveResponse = await requestPaypalApprove(plan, orderId);

    if (type === 'purchase') {
        const data = await savePaypalPayment(
            plan,
            orderId,
            approveResponse.data.payment_source.paypal.account_id,
            approveResponse.data.purchase_units[0].payee.merchant_id,
            approveResponse.data.purchase_units[0].payments.authorizations[0].id,
        );

        return data;
    }
    const data = await upgradePaypalPayment(
        plan,
        orderId,
        approveResponse.data.payment_source.paypal.account_id,
        approveResponse.data.purchase_units[0].payee.merchant_id,
        approveResponse.data.purchase_units[0].payments.authorizations[0].id,
    );

    return data;
};

export const requestPaypalPaymentAPI = () => {
    return useMutation(({ type, plan, orderId }: { type: 'purchase' | 'upgrade'; plan: string; orderId: string }) =>
        requestPaypalPayment(type, plan, orderId),
    );
};

const terminatePaypalPlan = async (reasonList: string[], opinion: string) => {
    const { data } = await api.post('/payment/paypal_clearplan', {
        reason_clear: reasonList.join(','),
        option: opinion,
    });
    return data;
};
export const terminatePaypalPlanAPI = () => {
    return useMutation(({ reasonList, opinion }: { reasonList: string[]; opinion: string }) =>
        terminatePaypalPlan(reasonList, opinion),
    );
};
