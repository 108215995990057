import Axios, { AxiosError } from 'axios';
import { jwtDecode } from 'jwt-decode';
import { deleteToken, getCookie, getUserToken, setToken } from '@modules/utils/cookie';

const checkAccessToken = async () => {
    const userToken = getUserToken();
    if (userToken === null) return null;

    const decoded = jwtDecode(userToken);
    if (!decoded.exp) return null;

    if (decoded.exp - new Date().getTime() / 1000 < 30) {
        const headers = { 'Content-Type': 'application/json', Authorization: `Bearer ${getCookie('rT')}` };
        try {
            const res = await fetch(
                `${
                    process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                        ? process.env.NEXT_PUBLIC_API_URL_DEV
                        : process.env.NEXT_PUBLIC_API_URL
                }/member/getrefresh`,
                {
                    headers,
                    method: 'post',
                },
            );
            const data = await res.json();

            if (data.success) {
                if (data.code === 8003) {
                    setToken(data.data.data.token);
                    return data.data.data.token;
                }
                if (data.code === 8004) {
                    deleteToken();
                    return Promise.reject(Error('token expired'));
                }
            }
            throw data;
        } catch (e: any) {
            // console.log(e);
            deleteToken();
            return Promise.reject(Error('unknown'));
        }
    }
    return userToken;
};

const axios = Axios.create({
    baseURL:
        process.env.NEXT_PUBLIC_NODE_ENV === 'development'
            ? process.env.NEXT_PUBLIC_API_URL_DEV
            : process.env.NEXT_PUBLIC_API_URL,
    withCredentials: true,
});

// 요청 인터셉터
axios.interceptors.request.use(async (config) => {
    // 요청하기 전에 수행
    const { lang } = document.documentElement;
    config.headers.lang = lang === 'ko' ? 'kr' : lang;

    const response = await fetch('https://api.ip.pe.kr/json/');
    const data = await response.json();
    window.localStorage.setItem('IP', data.ip);
    window.localStorage.setItem('countryCode', data.country_code);
    const { ip } = data;
    config.headers.ip = ip;

    const token = await checkAccessToken();

    if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers['x-voli-api-key'] = token;
    }

    return config;
});

// 응답 인터셉터
axios.interceptors.response.use(
    (res) => {
        // 응답 데이터 가공
        return res;
    },
    async (err: AxiosError) => {
        // 오류 응답 처리
        if (err.message === 'token expired' || err.message === 'unknown') {
            window.location.href = `${
                process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? process.env.NEXT_PUBLIC_URL_DEV
                    : process.env.NEXT_PUBLIC_URL
            }/login`;
            return;
        }
        // eslint-disable-next-line consistent-return
        return Promise.reject(err.response);
    },
);
export default axios;
