import { useRouter } from 'next/router';
import Link from 'next/link';
import { useRecoilValue } from 'recoil';
import useTranslation from '@modules/hooks/useTranslation';
import { User } from '@modules/state/user';
import { Mobile } from '@components/MediaQuery';
import styles from './index.module.scss';

interface navType {
    name: string;
    link: string;
    beta?: boolean;
}

const Nav = () => {
    const { t, locale } = useTranslation('common');
    const text = t('header');

    const router = useRouter();
    const { pathname } = router;

    const { email } = useRecoilValue(User);

    const handleLanguage = () => {
        router.push(
            {
                pathname: router.pathname,
                query: router.query,
            },
            {
                pathname: router.pathname,
                query: router.query,
            },
            { locale: locale === 'ko' ? 'en' : 'ko' },
        );
    };

    return (
        <nav className={styles.nav}>
            <ul className={styles.link_list}>
                {t('header.navList').map((item: navType, index: number) => (
                    <li
                        key={index}
                        className={`${styles.link_item} ${
                            (item.link !== '/' ? pathname.includes(item.link) : pathname === item.link)
                                ? styles.active
                                : ''
                        }`}
                    >
                        <Link href={item.link}>
                            <a>
                                {item.name}
                                {item.beta && <span> BETA</span>}
                            </a>
                        </Link>
                    </li>
                ))}
            </ul>

            <Mobile>
                {email === '' && (
                    <Link href="/login">
                        <a className={styles.join}>{text.join}</a>
                    </Link>
                )}

                <button type="button" onClick={handleLanguage} className={styles.language}>
                    {locale === 'ko' ? '한국어' : 'English'}
                </button>
            </Mobile>
        </nav>
    );
};

export default Nav;
