import { useEffect, useRef } from 'react';
import styles from '@styles/common2.module.scss';

interface Props {
    isToast: boolean;
    iconSrc: string;
    text: string;
    subText?: string;
    guide?: string;
    onClose?: () => void; // isToast === true 일 때
    onCloseClick?: () => void; // isToast === false 일 때
}

const Toast = ({ isToast, iconSrc, text, subText, guide, onClose, onCloseClick }: Props) => {
    const timerRef = useRef<number>();

    useEffect(() => {
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, []);

    useEffect(() => {
        if (isToast && onClose) {
            timerRef.current = window.setTimeout(() => {
                onClose();
            }, 1500);
        }
    }, [isToast]);

    return (
        <div role="presentation" onClick={onCloseClick} className={styles.background_modal}>
            <div role="presentation" onClick={(e) => e.stopPropagation()} className={styles.modal}>
                <div className={styles.content}>
                    <img src={iconSrc} alt="icon" width={64} height={64} />
                    <div className={styles.text_area}>
                        <p className={styles.text}>
                            {text.split('\n').map((item, index) => (
                                <span key={index}>
                                    {item}
                                    <br />
                                </span>
                            ))}
                        </p>
                        {subText && <span className={styles.sub_text}>{subText}</span>}
                        {guide && (
                            <span className={styles.guide}>
                                {guide.split('\n').map((item, index) => (
                                    <span key={index}>
                                        {item}
                                        <br />
                                    </span>
                                ))}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Toast;
