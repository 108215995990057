import { useRouter } from 'next/router';
import { useRecoilValue } from 'recoil';
import { IsMobileSize } from '@modules/state/common';
import styles from './index.module.scss';

const Logo = () => {
    const { pathname, locale } = useRouter();

    const isMobile = useRecoilValue(IsMobileSize);

    return (
        <a
            href={
                !pathname.includes('/dashboard')
                    ? process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                        ? `${process.env.NEXT_PUBLIC_URL_DEV}/${locale}`
                        : `${process.env.NEXT_PUBLIC_URL}/${locale}`
                    : process.env.NEXT_PUBLIC_NODE_ENV === 'development'
                    ? `${process.env.NEXT_PUBLIC_URL_DEV}/${locale}/studio/text-to-speech`
                    : `${process.env.NEXT_PUBLIC_URL}/${locale}/studio/text-to-speech`
            }
            className={styles.logo}
        >
            <img src="/logo.svg" alt="logo" width={!isMobile ? 48 : 40.14} height={!isMobile ? 24 : 20.14} />
            <img src="/VOLI.svg" alt="VOLI" width={!isMobile ? 56 : 47} height={!isMobile ? 18 : 15} />
            {pathname === '/actors' && (
                <img
                    src="/actors.png"
                    alt="actors"
                    width={!isMobile ? 36 : 23.52}
                    height={!isMobile ? 9 : 5.34}
                    className={styles.actors}
                />
            )}
            <h1>VOLI</h1>
        </a>
    );
};

export default Logo;
