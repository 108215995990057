import { useRouter } from 'next/router';
import ko from '../../../public/locales/ko';
import en from '../../../public/locales/en';

const test = (text: string, variables: { [key: string]: any }) => {
    let tmp = '';
    for (let i = 0; i < Object.keys(variables).length; i++) {
        tmp = text.replace(`{{ ${Object.keys(variables)[i]} }}`, variables[Object.keys(variables)[i]]);
    }

    return tmp;
};
const useTranslation = (fileName: string) => {
    const { locale } = useRouter();

    const t = (text: string, variables?: { [key: string]: any }) => {
        if (locale === 'en') {
            if (!text.includes('.')) {
                if (variables) {
                    return test(en[fileName][text], variables);
                }
                return en[fileName][text];
            }
            let tmp: any = en[fileName];
            for (let i = 0; i < text.split('.').length; i++) {
                tmp = tmp[text.split('.')[i]];
            }

            if (variables) {
                return test(tmp, variables);
            }
            return tmp;
        }

        if (!text.includes('.')) {
            if (variables) {
                return test(ko[fileName][text], variables);
            }
            return ko[fileName][text];
        }
        let tmp: any = ko[fileName];
        for (let i = 0; i < text.split('.').length; i++) {
            tmp = tmp[text.split('.')[i]];
        }

        if (variables) {
            return test(tmp, variables);
        }
        return tmp;
    };

    return { t, locale };
};

export default useTranslation;
